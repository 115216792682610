<template>
  <div class="profile">
    <div class="plr15">
      <div class="flex_between flex_items bbe9s ptb20">
        <span class="f15 c0">Avatar</span>
        <div class="flex_start flex_items">
          <van-uploader :after-read="afterRead">
            <van-image
              v-if="avatar != null"
              fit="contain"
              :src="avatar"
              class="mr5"
              width="30"
              round
            ></van-image>
            <van-image
              v-else
              fit="contain"
              :src="require('@/images/home/mtx.png')"
              class="mr5"
              width="30"
              round
            ></van-image>
          </van-uploader>
          <van-image
            fit="contain"
            :src="require('@/images/home/myright.png')"
            class="mr10"
            width="12"
          ></van-image>
        </div>
      </div>
      <!-- 2 -->
      <div
        class="flex_between flex_items bbe9s ptb20"
        @click="changename('name')"
      >
        <span class="f15 c0">Nickname</span>
        <div class="flex_start flex_items">
          <span class="f13" style="color: #b9b9b9">{{ Nickname }}</span>
          <van-image
            fit="contain"
            :src="require('@/images/home/myright.png')"
            class="mr10"
            width="12"
          ></van-image>
        </div>
      </div>
      <!-- 3 -->
      <div
        class="flex_between flex_items bbe9s ptb20"
        @click="changename('gender')"
      >
        <span class="f15 c0">Gender</span>
        <div class="flex_start flex_items">
          <span v-if="Gender == 1" class="f13" style="color: #b9b9b9"
            >Male</span
          >
          <span v-if="Gender == 2" class="f13" style="color: #b9b9b9"
            >Female</span
          >
          <van-image
            fit="contain"
            :src="require('@/images/home/myright.png')"
            class="mr10"
            width="12"
          ></van-image>
        </div>
      </div>
      <!-- 4 -->
      <template>
        <VueCountryIntl
          schema="popover"
          v-model="phoneCountry"
          :onlyValue="true"
          searchInputPlaceholder="Search"
          noDataText="No data"
          :useChinese="false"
          :showAreaCode="false"
          style="width: 100%"
          type="country"
          @onChange="changelocation"
        >
          <div slot="reference">
            <div class="flex_between flex_items bbe9s ptb20">
              <span class="f15 c0">Country or Region</span>
              <div class="flex_start flex_items">
                <span class="f13" v-if="info.location" style="color: #b9b9b9">{{
                  phoneCountry
                }}</span>
                <van-image
                  fit="contain"
                  :src="require('@/images/home/myright.png')"
                  class="mr10"
                  width="12"
                ></van-image>
              </div>
            </div>
          </div>
        </VueCountryIntl>
      </template>
      <!--5 -->
      <div
        class="flex_between flex_items bbe9s ptb20"
        @click="changename('Introdution')"
      >
        <span class="f15 c0">Introdution</span>
        <div class="flex_start flex_items">
          <span class="f13" style="color: #b9b9b9">{{Introdution}}</span>
          <van-image
            fit="contain"
            :src="require('@/images/home/myright.png')"
            class="mr10"
            width="12"
          ></van-image>
        </div>
      </div>
    </div>
    <van-popup v-model="showPopup">
      <div class="flex_items flex_center" style="width: 100%; height: 250px">
        <div v-if="kind == 'name'" class="flex_column plr20">
          <p class="f14 c0">Change Your Nickname</p>
          <van-cell-group class="mt10">
            <van-field
              v-model="Nickname2"
              placeholder="Input..."
              style="width: 250px; border: 1px solid #ccc"
            />
          </van-cell-group>
          <van-button
            style="margin-top: 20px"
            class="mt20"
            type="warning"
            @click="subname"
            >Submit</van-button
          >
        </div>
        <div
          v-if="kind == 'gender'"
          class="flex_column plr20"
          style="width: 250px"
        >
          <van-radio-group v-model="Gender2">
            <van-radio name="1">Male</van-radio>
            <van-radio name="2" style="margin-top: 20px">Female</van-radio>
          </van-radio-group>
          <van-button
            style="margin-top: 20px"
            class="mt20"
            type="warning"
            @click="subname"
            >Submit</van-button
          >
        </div>
        <div
          v-if="kind == 'Introdution'"
          class="flex_column plr20"
          style="width: 250px"
        >
          <p class="f14 c0">Change Your Introdution</p>
          <van-field
            v-model="Introdution2"
            rows="1"
            autosize
            type="textarea"
            placeholder="Input..."
          />
          <van-button
            style="margin-top: 20px"
            class="mt20"
            type="warning"
            @click="subname"
            >Submit</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      avatar: null,
      kind: "name",
      Nickname: "",
      Nickname2: "",
      Gender: "",
      Gender2: "2",
      Country: "",
      phoneCountry: "",
      Introdution: "",
      Introdution2: "",
      showPopup: false,
      info: {},
    };
  },
  created() {
    this.avatar = this.$route.params.headerurl;
    this.Nickname = this.$route.params.name;
    this.info = this.$route.params.info;
    this.Nickname2 = this.Nickname;
    this.Gender = this.$route.params.gender.toString();
    this.Gender2 = this.$route.params.gender.toString();
  },
  methods: {
    afterRead(file) {
      Toast.loading({
        message: "Loading...",
        forbidClick: true,
      });
      var formdata = new FormData();
      formdata.append("avatar", file.file);
      //   formdata.append("brief", "");
      //   formdata.append("email", "");
      //   formdata.append("gender", "");
      //   formdata.append("location", "");
      //   formdata.append("name", "hahahhaha");
      //   formdata.append("phone", "");
      this.getData(formdata);
    },
    changename(idx) {
      this.kind = idx;
      this.showPopup = true;
    },
    changelocation(selected, value) {
      Toast.loading({
        message: "Loading...",
        forbidClick: true,
      });
      var formdata = new FormData();
      formdata.append("location", selected.name);
      this.getData(formdata);
    },
    subname() {
      Toast.loading({
        message: "Loading...",
        forbidClick: true,
      });
      var formdata = new FormData();
      if (this.kind == "name") {
        formdata.append("name", this.Nickname2);
      }
      if (this.kind == "gender") {
        formdata.append("gender", this.Gender2);
      }
      if (this.kind == "Introdution") {
        formdata.append("brief", this.Introdution2);
      }
      this.getData(formdata);
    },
    getData(formdata) {
      this.util.mpp(this.HOST + "/account/user", formdata).then((res) => {
        console.log(res);
        if (res.code == "000") {
          this.avatar = res.data.headerUrl;
          this.Nickname = res.data.nickname;
          this.Gender = res.data.gender.toString();
          this.phoneCountry = res.data.location;
          this.info.location = res.data.location;
          this.Introdution = res.data.brief;
          //   this.Introdution=
        } else {
          Toast(res.msg);
        }
        this.showPopup = false;
      });
    },
  },
};
</script>
<style>
</style>